import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const CommentContainer = styled.div`
  margin-top: 20px;
`;

const CommentForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const CommentInputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const CommentInputBox = styled.div`
  flex: 1;
  height: 200px;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  border: 1px black solid;
`;

const CommentInput = styled.textarea`
  width: 90%;
  height: 150px;
  margin: 10px;
  padding: 10px;
  border: none;
  outline: none;
  resize: none;
  font-size: 14px;
  font-family: 'Noticia Text', serif;
`;

const SubmitButtonContainer = styled.div`
  width: 158px;
  height: 200px;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  border: 1px black solid;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubmitButton = styled.button`
  background: none;
  border: none;
  font-size: 20px;
  font-family: 'Noticia Text', serif;
  font-weight: 700;
  cursor: pointer;
`;

const CommentList = styled.div`
  margin-top: 20px;
`;

const CommentItem = styled.div`
  border-bottom: 1px solid #eee;
  padding: 10px 0;
`;

const LikeDislikeContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 20px;
`;

const LikeDislikeButton = styled.div`
  width: 180px;
  height: 30px;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  border: 4px solid ${props => props.like ? '#1B7CD7' : '#C3333E'};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-family: 'Noticia Text', serif;
  font-weight: 700;
  cursor: pointer;
`;

const ReportButton = styled.div`
  width: 98px;
  text-align: center;
  color: black;
  font-size: 20px;
  font-family: 'Noticia Text', serif;
  font-weight: 700;
  line-height: 23.26px;
  cursor: pointer;
  margin-top: 10px;
`;



const CommentSection = ({ postId }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [likes, setLikes] = useState(0);
  const [dislikes, setDislikes] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    setIsLoggedIn(!!token);
  }, []);

  const fetchComments = useCallback(async () => {
    try {
      const { data } = await axios.get(`https://api.koreamate.net/boards/posts/${postId}/comments/`);
      setComments(data);  // response.data 대신 data만 사용
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  }, [postId]);

  const fetchLikesDislikes = useCallback(async () => {
    try {
      const response = await axios.get(`https://api.koreamate.net/boards/posts/${postId}/likes-dislikes/`);
      setLikes(response.data.likes);
      setDislikes(response.data.dislikes);
    } catch (error) {
      console.error('Error fetching likes/dislikes:', error);
    }
  }, [postId]);

  useEffect(() => {
    fetchComments();
    fetchLikesDislikes();
  }, [postId, fetchComments, fetchLikesDislikes]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('accessToken');
    if (!token) {
      alert('로그인이 필요합니다.');
      return;
    }
    try {
      const response = await axios.post(
        `https://api.koreamate.net/boards/posts/${postId}/comments/`,
        { content: newComment },
        { headers: { 'Authorization': `Bearer ${token}` } }
      );
      setNewComment('');
      fetchComments();
      console.log('Comment posted successfully:', response.data);  // response 사용 예시
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert('인증에 실패했습니다. 다시 로그인해주세요.');
      } else {
        console.error('Error posting comment:', error);
        alert('댓글 등록 중 오류가 발생했습니다.');
      }
    }
  };
  

  const handleLike = async () => {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      alert('로그인이 필요합니다.');
      return;
    }
    try {
      await axios.post(
        `https://api.koreamate.net/boards/posts/${postId}/like/`,
        {},
        { headers: { 'Authorization': `Bearer ${token}` } }
      );
      setLikes(likes + 1);
    } catch (error) {
      console.error('Error liking post:', error);
      alert('좋아요 처리 중 오류가 발생했습니다.');
    }
  };

  const handleDislike = async () => {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      alert('로그인이 필요합니다.');
      return;
    }
    try {
      await axios.post(
        `https://api.koreamate.net/boards/posts/${postId}/dislike/`,
        {},
        { headers: { 'Authorization': `Bearer ${token}` } }
      );
      setDislikes(dislikes + 1);
    } catch (error) {
      console.error('Error disliking post:', error);
      alert('싫어요 처리 중 오류가 발생했습니다.');
    }
  };

  return (
    <CommentContainer>
      <h3>댓글</h3>
      <LikeDislikeContainer>
        <LikeDislikeButton like onClick={handleLike}>GOOD {likes}</LikeDislikeButton>
        <LikeDislikeButton onClick={handleDislike}>BAD {dislikes}</LikeDislikeButton>
      </LikeDislikeContainer>
      <ReportButton>신고하기</ReportButton>
      <CommentForm onSubmit={handleSubmit}>
        <CommentInputContainer>
          <CommentInputBox>
            <CommentInput
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              placeholder={isLoggedIn ? "댓글을 입력하세요..." : "댓글을 작성하려면 로그인이 필요합니다."}
              disabled={!isLoggedIn}
            />
          </CommentInputBox>
          <SubmitButtonContainer>
            <SubmitButton type="submit" disabled={!isLoggedIn}>
              댓글 등록
            </SubmitButton>
          </SubmitButtonContainer>
        </CommentInputContainer>
      </CommentForm>
      <CommentList>
        {comments.map((comment) => (
          <CommentItem key={comment.id}>
            <p>{comment.content}</p>
            <small>
              작성자: {comment.author} | 
              작성일: {new Date(comment.created_at).toLocaleString()}
            </small>
          </CommentItem>
        ))}
      </CommentList>
    </CommentContainer>
  );
};

export default CommentSection;