import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import AuthContext from '../contexts/AuthContext';

const LoginContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 38px 0;
`;

const Title = styled.div`
  width: 120px;
  height: 50px;
  text-align: center;
  color: black;
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  line-height: 23.26px;
  margin-bottom: 10px;
`;

const InputBox = styled.div`
  width: 360px;
  height: 60px;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  border: 1px solid black;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const Input = styled.input`
  width: 320px;
  height: 33px;
  margin-left: 20px;
  border: none;
  outline: none;
  color: #BDC0C1;
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  line-height: 23.26px;

  &::placeholder {
    color: #BDC0C1;
  }
`;

const AutoLoginContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const Checkbox = styled.input`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

const AutoLoginText = styled.div`
  color: black;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  line-height: 16.28px;
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 360px;
  margin-top: 20px;
`;

const Link = styled.a`
  color: black;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  line-height: 18.61px;
  text-decoration: none;
  cursor: pointer;
`;

const SocialLoginContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

const SocialIcon = styled.div`
  width: 32px;
  height: 32px;
  margin: 0 10px;
  cursor: pointer;
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const SubmitButton = styled.button`
  width: 360px;
  height: 60px;
  background: black;
  color: white;
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  line-height: 23.26px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  margin-top: 10px;

  &:disabled {
    background: #BDC0C1;
    cursor: not-allowed;
  }
`;

const Login = () => {
  const { login, loading } = useContext(AuthContext); // Retrieve loading from AuthContext
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    login(email, password);
  };

  return (
    <LoginContainer>
      <Title>로그인</Title>
      <form onSubmit={handleSubmit}>
        <InputBox>
          <Input 
            type="email" 
            placeholder="아이디" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            required 
          />
        </InputBox>
        <InputBox>
          <Input 
            type="password" 
            placeholder="비밀번호" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            required 
          />
        </InputBox>

        <AutoLoginContainer>
          <Checkbox type="checkbox" id="autoLogin" />
          <AutoLoginText>자동 로그인</AutoLoginText>
        </AutoLoginContainer>
        <LinkContainer>
          <Link href="/signup">회원가입</Link>
          <Link href="/find-id">아이디 찾기</Link>
          <Link href="/find-password">비밀번호 찾기</Link>
        </LinkContainer>
        <SubmitButtonContainer>
          <SubmitButton type="submit" disabled={loading}>
            {loading ? '로그인 중...' : '로그인'}
          </SubmitButton>
        </SubmitButtonContainer>
      </form>
      <SocialLoginContainer>
        <SocialIcon>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 208 191.94" width="32" height="32">
            <path fill="#3A1D1D" d="M0 191.94V0h208v191.94z"/>
            <path fill="#FFE812" d="M108.97 145.12c-23.47 0-42.52-15.02-42.52-33.53 0-18.52 19.05-33.53 42.52-33.53 23.47 0 42.52 15.02 42.52 33.53 0 18.52-19.05 33.53-42.52 33.53z"/>
          </svg>
        </SocialIcon>
        <SocialIcon>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 170 170" width="32" height="32">
            <path d="M150.37 130.25c-2.45 5.66-5.35 10.87-8.71 15.66-4.58 6.53-8.33 11.05-11.22 13.56-4.48 4.12-9.28 6.23-14.42 6.35-3.69 0-8.14-1.05-13.32-3.18-5.197-2.12-9.973-3.17-14.34-3.17-4.58 0-9.492 1.05-14.746 3.17-5.262 2.13-9.501 3.24-12.742 3.35-4.929 0.21-9.842-1.96-14.746-6.52-3.13-2.73-7.045-7.41-11.735-14.04-5.032-7.08-9.169-15.29-12.41-24.65-3.471-10.11-5.211-19.9-5.211-29.378 0-10.857 2.346-20.221 7.045-28.068 3.693-6.303 8.606-11.275 14.755-14.925s12.793-5.51 19.948-5.629c3.915 0 9.049 1.211 15.429 3.591 6.362 2.388 10.447 3.599 12.238 3.599 1.339 0 5.877-1.416 13.57-4.239 7.275-2.618 13.415-3.702 18.445-3.275 13.63 1.1 23.87 6.473 30.68 16.153-12.19 7.386-18.22 17.731-18.1 31.002 0.11 10.337 3.86 18.939 11.23 25.769 3.34 3.17 7.07 5.62 11.22 7.36-0.9 2.61-1.85 5.11-2.86 7.51zM119.11 7.24c0 8.102-2.96 15.667-8.86 22.669-7.12 8.324-15.732 13.134-25.071 12.375-0.119-0.972-0.188-1.995-0.188-3.07 0-7.778 3.386-16.102 9.399-22.908 3.002-3.446 6.82-6.311 11.45-8.597 4.62-2.252 8.99-3.497 13.1-3.71 0.12 1.083 0.17 2.166 0.17 3.24z"/>
          </svg>
        </SocialIcon>
        <SocialIcon>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="32" height="32">
            <path fill="#3F51B5" d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"/>
            <path fill="#FFF" d="M34.368,25H31v13h-5V25h-3v-4h3v-2.41c0.002-3.508,1.459-5.59,5.592-5.59H35v4h-2.287C31.104,17,31,17.6,31,18.723V21h4L34.368,25z"/>
          </svg>
        </SocialIcon>
        <SocialIcon>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="32" height="32">
            <path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"/>
            <path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"/>
            <path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"/>
            <path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"/>
          </svg>
        </SocialIcon>
      </SocialLoginContainer>
    </LoginContainer>
  );
};

export default Login;
