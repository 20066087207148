import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import Home from './pages/Home';
import HotGround from './pages/HotGround';
import PlayGround from './pages/PlayGround';
import Visa from './pages/Visa';
import Job from './pages/Job';
import Language from './pages/Language';
import Life from './pages/Life';
import Event from './pages/Event';
import QnA from './pages/QnA';
import SignUp from './pages/SignUp';
import Login from './pages/Login';
import Search from './pages/Search';
import PostDetail from './pages/PostDetail';


const App = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/hot-ground" element={<HotGround />} />
        <Route path="/play-ground" element={<PlayGround />} />
        <Route path="/visa" element={<Visa />} />
        <Route path="/job" element={<Job />} />
        <Route path="/language" element={<Language />} />
        <Route path="/life" element={<Life />} />
        <Route path="/event" element={<Event />} />
        <Route path="/qna" element={<QnA />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/search" element={<Search />} />
        <Route path="/post/:id" element={<PostDetail />} /> 
      </Routes>
      <Footer />
    </>
  );
};

export default App;
