import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Banner from '../components/common/Banner';
import axios from 'axios';
import { Link } from 'react-router-dom';

const PageContainer = styled.div`
  width: 100%;
  max-width: 1160px;
  margin: 0 auto;
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
`;

const ContentBox = styled.div`
  background: white;
  border-radius: 10px;
  border: 1px solid black;
  padding: 20px;
  margin-bottom: 20px;
`;

const PostItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;
`;

const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const SearchInput = styled.input`
  width: 100%;
  max-width: 500px; /* Adjust max-width as needed */
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PageButton = styled.button`
  padding: 10px 20px;
  margin: 0 5px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
`;

const PostLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: underline;
  }
`;

const Event = () => {
  const [posts, setPosts] = useState([]);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10;

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get('https://api.koreamate.net/boards/posts', {
          params: {
            category: 'EVENT',
            search,
            page: currentPage,
            page_size: pageSize
          },
        });
        setPosts(response.data.results);
        setTotalPages(response.data.total_pages);
      } catch (error) {
        console.error('Error fetching posts', error);
      }
    };
    
    fetchPosts();
  }, [search, currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <Banner backgroundImage="https://picsum.photos/1160/200">
        이벤트 게시판 입니다.
      </Banner>
      <PageContainer>
        <Title>이벤트</Title>
        <SearchContainer>
          <SearchInput
            type="text"
            placeholder="Search posts..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </SearchContainer>
        <ContentBox>
          {posts.map((post) => (
            <PostItem key={post.id}>
              <PostLink to={`/post/${post.id}`}>{post.title}</PostLink>
              <div>
                <span>{new Date(post.date_created).toLocaleDateString()}</span>
                <span style={{ marginLeft: '10px' }}>{new Date(post.date_created).toLocaleTimeString()}</span>
              </div>
            </PostItem>
          ))}
        </ContentBox>
        <Pagination>
          <PageButton 
            onClick={() => handlePageChange(currentPage - 1)} 
            disabled={currentPage === 1}
          >
            Previous
          </PageButton>
          {[...Array(totalPages)].map((_, index) => (
            <PageButton 
              key={index + 1} 
              onClick={() => handlePageChange(index + 1)}
              disabled={currentPage === index + 1}
            >
              {index + 1}
            </PageButton>
          ))}
          <PageButton 
            onClick={() => handlePageChange(currentPage + 1)} 
            disabled={currentPage === totalPages}
          >
            Next
          </PageButton>
        </Pagination>
      </PageContainer>
    </>
  );
};

export default Event;
