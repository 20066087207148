import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
// import { API_URL } from '../config';
const AuthContext = createContext();

const API_URL = 'https://api.koreamate.net'; // Ensure this is the correct API URL

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      const userInfo = JSON.parse(localStorage.getItem('user'));
      setUser(userInfo);
    }
    setLoading(false);
  }, []);

  const login = async (email, password) => {
    try {
      const response = await axios.post(`${API_URL}/users/login`, { login_type: 'email', email, password });
      console.log('API Response:', response.data);

      localStorage.setItem('accessToken', response.data.token.access);
      localStorage.setItem('refreshToken', response.data.token.refresh);
      localStorage.setItem('user', JSON.stringify(response.data.user));

      // Verify localStorage values
      console.log('LocalStorage accessToken:', localStorage.getItem('accessToken'));
      console.log('LocalStorage refreshToken:', localStorage.getItem('refreshToken'));
      console.log('LocalStorage user:', localStorage.getItem('user'));

      setUser(response.data.user);
      navigate('/');
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  const logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('user');
    setUser(null);
    navigate('/login');
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, loading }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthContext;