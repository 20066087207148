import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const HeaderContainer = styled.div`
  width: 100%;
  max-width: 1160px;
  margin: 0 auto;
  height: 100px;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  box-sizing: border-box;
`;

const LogoNavContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled(Link)`
  color: #BC2649;
  font-size: 28px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  line-height: 1;
  text-decoration: none;
  margin-right: 40px;
`;

const NavContainer = styled.nav`
  display: flex;
  align-items: center;
`;

const NavItem = styled(Link)`
  margin-left: 20px;
  text-align: center;
  color: black;
  font-size: 16px;
  font-family: 'Noticia Text', serif;
  font-weight: 700;
  line-height: 1.2;
  text-decoration: none;
`;

const TwoLineNavItem = styled(NavItem)`
  display: flex;
  flex-direction: column;
`;

const ButtonSearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const Button = styled(Link)`
  width: 80px;
  height: 30px;
  border-radius: 50px;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  line-height: 1;
  cursor: pointer;
  margin-left: 10px;
  text-decoration: none;
  color: black;
`;

const SearchBarContainer = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
`;


const SearchBar = styled.form`
  width: 250px;
  height: 35px;
  padding: 5px 10px;
  background: white;
  border-radius: 10px;
  border: 1px solid #D9D9D9;
  display: flex;
  align-items: center;
  box-sizing: border-box;
`;

const SearchInput = styled.input`
  margin-left: 10px;
  border: none;
  outline: none;
  width: 100%;
  color: #616161;
  font-size: 14px;
`;

const ErrorMessage = styled.div`
  color: #FF0000;
  font-size: 12px;
  margin-top: 5px;
  text-align: left;
  padding-left: 10px;
`;

const GoogleTranslateWrapper = styled.div`
  margin-left: 10px;
  .goog-te-banner-frame.skiptranslate {
    display: none !important;
  }
  .goog-te-gadget {
    font-size: 0;
  }
  .goog-te-gadget .goog-te-combo {
    margin: 0;
    padding: 5px;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    font-size: 14px;
    color: #616161;
    background: white;
  }
`;

const Header = () => {
  const { user, logout } = useAuth();
  const [search, setSearch] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (search.trim() === '') {
      setError('검색 내용을 입력하세요');
    } else {
      setError('');
      navigate(`/search?query=${search}`);
    }
  };

  return (
    <HeaderContainer>
      <LogoNavContainer>
        <Logo to="/">Mate's board</Logo>
        <NavContainer>
          <TwoLineNavItem to="/hot-ground">
            <span>Hot</span>
            <span>ground</span>
          </TwoLineNavItem>
          <TwoLineNavItem to="/play-ground">
            <span>Play</span>
            <span>ground</span>
          </TwoLineNavItem>
          <NavItem to="/visa">VISA</NavItem>
          <NavItem to="/job">JOB</NavItem>
          <NavItem to="/language">Language</NavItem>
          <NavItem to="/life">Life</NavItem>
          <NavItem to="/event">Event</NavItem>
          <NavItem to="/qna">Q & A</NavItem>
        </NavContainer>
      </LogoNavContainer>
      <ButtonSearchContainer>
      <ButtonContainer>
          {user ? (
            <Button to="/" onClick={logout}>로그아웃</Button>
          ) : (
            <>
              <GoogleTranslateWrapper id="google_translate_element" />
              <Button to="/signup">회원가입</Button>
              <Button to="/login">로그인</Button>
            </>
          )}
        </ButtonContainer>
        <SearchBarContainer>
          <SearchBar onSubmit={handleSearchSubmit}>
            <SearchInput
              type="text"
              placeholder="Search for word in adverts"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                setError('');
              }}
            />
          </SearchBar>
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </SearchBarContainer>
      </ButtonSearchContainer>
    </HeaderContainer>
  );
};

export default Header;
