import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { checkNickname, checkEmail, verifyEmail, signUp } from '../services/api'; 

const SignUpContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background: white;
  padding: 20px 0;
`;

const Title = styled.div`
  width: 120px;
  height: 50px;
  position: absolute;
  left: 50%;
  top: 38px;
  transform: translateX(-50%);
  text-align: center;
  color: black;
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  line-height: 23.26px;
`;

const MemberTypeContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 150px;
`;

const MemberTypeBox = styled.div`
  width: 197.92px;
  height: 200px;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const MemberTypeTitle = styled.span`
  color: #17262A;
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  line-height: 23.26px;
`;

const MemberTypeDescription = styled.span`
  color: #17262A;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  line-height: 16.28px;
  margin-top: 10px;
`;

const FormContainer = styled.div`
  width: 800px;
  height: 397px;
  margin: 41px auto;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  border: 1px solid black;
  padding: 20px;
  position: relative;
`;

const FormContent = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const SubmitButton = styled.button`
  width: 400px;
  height: 50px;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: 1px solid black;
  color: black;
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  line-height: 23.26px;
  cursor: pointer;
`;

const FormTitle = styled.div`
  color: #17262A;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  line-height: 16.28px;
  margin-bottom: 20px;
`;

const FormField = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  color: #17262A;
  font-size: 13px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  line-height: 15.12px;
  margin-bottom: 5px;
`;

const Input = styled.input`
  width: 350px;
  height: 30px;
  background: white;
  border-radius: 5px;
  border: 1px solid black;
  padding: 0 10px;
`;

const Button = styled.button`
  width: 168px;
  height: 30px;
  background: white;
  border-radius: 5px;
  border: 1px solid black;
  color: #17262A;
  font-size: 13px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  line-height: 15.12px;
  cursor: pointer;
  margin-left: 10px;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 5px;
`;

// const SuccessMessage = styled.div`
//   color: green;
//   font-size: 12px;
//   margin-top: 5px;
// `;

const SignUp = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [memberType, setMemberType] = useState('');
  const [email, setEmail] = useState('');
  const [nickname, setNickname] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [emailError, setEmailError] = useState('');
  const [nicknameError, setNicknameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [verificationError, setVerificationError] = useState('');
  const [emailVerified, setEmailVerified] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleMemberTypeSelect = (type) => {
    setMemberType(type);
    setStep(2);
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return re.test(email);
  };

  const validatePassword = (password) => {
    const re = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    return re.test(password);
  };

  const handleEmailCheck = async () => {
    if (!validateEmail(email)) {
      setEmailError('유효한 이메일 주소를 입력해주세요.');
      return;
    }
  
    setLoading(true);
    try {
      const data = await checkEmail(email);
      if (data.message === "사용 가능한 이메일입니다.") {
        setEmailError('인증 코드가 전송되었습니다. 이메일을 확인해주세요.');
      }
    } catch (error) {
      setEmailError(error.response?.data?.message || '이메일 중복 확인 중 오류가 발생했습니다.');
    } finally {
      setLoading(false);
    }
  };
  
  const handleVerifyEmail = async () => {
    setLoading(true);
    try {
      await verifyEmail(email, verificationCode);
      setEmailVerified(true);
      setVerificationError('');
    } catch (error) {
      setVerificationError('인증 코드가 올바르지 않습니다.');
    } finally {
      setLoading(false);
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!emailVerified) {
      setEmailError('이메일 인증을 완료해주세요.');
      return;
    }
    if (password !== confirmPassword) {
      setPasswordError('비밀번호가 일치하지 않습니다.');
      return;
    }
    if (!validatePassword(password)) {
      setPasswordError('비밀번호는 8자 이상이며, 문자, 숫자, 특수문자를 포함해야 합니다.');
      return;
    }
  
    setLoading(true);
    try {
      await signUp({
        email,
        nickname,
        password,
        type: memberType,
      });
      alert('회원가입이 완료되었습니다.');
      navigate('/login');
    } catch (error) {
      alert(error.response?.data?.message || '회원가입 중 오류가 발생했습니다.');
    } finally {
      setLoading(false);
    }
  };

  // const sendVerificationEmail = async () => {
  //   try {
  //     await axios.post('/users/sendemail', { email });
  //     setEmailError('인증 코드가 전송되었습니다. 이메일을 확인해주세요.');
  //   } catch (error) {
  //     setEmailError('인증 코드 전송 중 오류가 발생했습니다.');
  //   }
  // };

  const handleNicknameCheck = async () => {
    if (nickname.length < 2) {
      setNicknameError('닉네임은 2자 이상이어야 합니다.');
      return;
    }
  
    setLoading(true);
    try {
      const data = await checkNickname(nickname);
      if (data.message === "사용 가능한 닉네임입니다.") {
        setNicknameError('');
      }
    } catch (error) {
      setNicknameError(error.response?.data?.message || '닉네임 중복 확인 중 오류가 발생했습니다.');
    } finally {
      setLoading(false);
    }
  };

  const renderStep1 = () => (
    <>
      <Title>회원가입</Title>
      <MemberTypeContainer>
        <MemberTypeBox onClick={() => handleMemberTypeSelect('customer')}>
          <MemberTypeTitle>일반 회원</MemberTypeTitle>
          <MemberTypeDescription>만 14세 이상 가입가능</MemberTypeDescription>
        </MemberTypeBox>
        <MemberTypeBox onClick={() => handleMemberTypeSelect('store')}>
          <MemberTypeTitle>사업자 회원</MemberTypeTitle>
          <MemberTypeDescription>개인 사업자 & 법인사업자</MemberTypeDescription>
        </MemberTypeBox>
      </MemberTypeContainer>
    </>
  );

  const renderStep2 = () => (
    <>
      <Title>회원가입</Title>
      <FormContainer>
        <FormContent>
          <FormTitle>회원정보입력</FormTitle>
          <FormField>
            <Label>이메일</Label>
            <Input 
              type="email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)}
              disabled={emailVerified}
            />
            <Button onClick={handleEmailCheck} disabled={loading || emailVerified}>
              {emailVerified ? '인증완료' : '인증코드 전송'}
            </Button>
            {emailError && <ErrorMessage>{emailError}</ErrorMessage>}
          </FormField>
          {!emailVerified && (
            <FormField>
              <Label>이메일 인증</Label>
              <Input 
                type="text" 
                value={verificationCode} 
                onChange={(e) => setVerificationCode(e.target.value)}
              />
              <Button onClick={handleVerifyEmail} disabled={loading}>확인</Button>
              {verificationError && <ErrorMessage>{verificationError}</ErrorMessage>}
            </FormField>
          )}
          <FormField>
            <Label>닉네임</Label>
            <Input 
              type="text" 
              value={nickname} 
              onChange={(e) => setNickname(e.target.value)}
            />
            <Button onClick={handleNicknameCheck} disabled={loading}>중복확인</Button>
            {nicknameError && <ErrorMessage>{nicknameError}</ErrorMessage>}
          </FormField>
          <FormField>
            <Label>비밀번호</Label>
            <Input 
              type="password" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormField>
          <FormField>
            <Label>비밀번호 확인</Label>
            <Input 
              type="password" 
              value={confirmPassword} 
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            {passwordError && <ErrorMessage>{passwordError}</ErrorMessage>}
          </FormField>
        </FormContent>
      </FormContainer>
      <SubmitButtonContainer>
        <SubmitButton onClick={handleSubmit} disabled={loading}>가입하기</SubmitButton>
      </SubmitButtonContainer>
    </>
  );

  return (
    <SignUpContainer>
      {step === 1 ? renderStep1() : renderStep2()}
    </SignUpContainer>
  );
};

export default SignUp;