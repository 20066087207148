import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
// import Header from '../components/common/Header';
// import Footer from '../components/common/Footer';
import CommentSection from '../components/posts/CommentSection';
import Banner from '../components/common/Banner';

const PageContainer = styled.div`
  width: 100%;
  max-width: 1160px;
  margin: 0 auto;
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
`;

const ContentBox = styled.div`
  background: white;
  border-radius: 10px;
  border: 1px solid black;
  padding: 20px;
  margin-bottom: 20px;
`;

const PostMeta = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 14px;
`;

const PostContainer = styled.div`
  background: white;
  border-radius: 10px;
  border: 1px solid black;
  padding: 20px;
  margin-bottom: 20px;
`;

const PostTitle = styled.h1`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
`;


const PostContent = styled.div`
  font-size: 16px;
  line-height: 1.6;
`;


const PostItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
`;

const PostLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: underline;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const SearchInput = styled.input`
  width: 100%;
  max-width: 500px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PageButton = styled.button`
  padding: 10px 20px;
  margin: 0 5px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
`;

const PostDetail = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [relatedPosts, setRelatedPosts] = useState([]);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10;
  const navigate = useNavigate();

  const fetchRelatedPosts = useCallback(
    async (category) => {
      try {
        const response = await axios.get('https://api.koreamate.net/boards/posts/', {
          params: {
            category: category,
            search,
            page: currentPage,
            page_size: pageSize,
          },
        });
        setRelatedPosts(response.data.results.filter((p) => p.id !== Number(id)));
        setTotalPages(response.data.total_pages);
      } catch (error) {
        console.error('Error fetching related posts', error);
      }
    },
    [search, currentPage, pageSize, id]
  );

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(`https://api.koreamate.net/boards/posts/${id}/`);
        setPost(response.data);
        fetchRelatedPosts(response.data.category);
      } catch (err) {
        console.error('Error fetching post', err);
      }
    };

    fetchPost();
  }, [id, fetchRelatedPosts]);

  useEffect(() => {
    if (post) {
      fetchRelatedPosts(post.category);
    }
  }, [search, currentPage, post, fetchRelatedPosts]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePostClick = (postId) => {
    if (postId === Number(id)) {
      window.scrollTo(0, 0);
    } else {
      navigate(`/post/${postId}`);
    }
  };

  if (!post) return <div>Loading...</div>;
  
    return (
      <>
        <Banner backgroundImage="https://picsum.photos/1160/200">
          {post.category} 게시판
        </Banner>
        <PageContainer>
          <PostContainer>
            <PostTitle>{post.title}</PostTitle>
            <PostMeta>
              <span>작성자: {post.author}</span>
              <span>작성일: {new Date(post.created_at).toLocaleString()}</span>
              <span>조회수: {post.views}</span>
            </PostMeta>
            <PostContent>{post.content}</PostContent>
          </PostContainer>
          
          <CommentSection postId={id} />
          
          <Title>{post.category} 게시판의 다른 글</Title>
          <SearchContainer>
            <SearchInput
              type="text"
              placeholder="Search posts..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </SearchContainer>
          <ContentBox>
            {relatedPosts.map((relatedPost) => (
              <PostItem key={relatedPost.id} onClick={() => handlePostClick(relatedPost.id)}>
                <PostLink to={`/post/${relatedPost.id}`}>{relatedPost.title}</PostLink>
                <div>
                  <span>{new Date(relatedPost.date_created).toLocaleDateString()}</span>
                  <span style={{ marginLeft: '10px' }}>{new Date(relatedPost.date_created).toLocaleTimeString()}</span>
                </div>
              </PostItem>
            ))}
          </ContentBox>
          <Pagination>
            <PageButton 
              onClick={() => handlePageChange(currentPage - 1)} 
              disabled={currentPage === 1}
            >
              Previous
            </PageButton>
            {[...Array(totalPages)].map((_, index) => (
              <PageButton 
                key={index + 1} 
                onClick={() => handlePageChange(index + 1)}
                disabled={currentPage === index + 1}
              >
                {index + 1}
              </PageButton>
            ))}
            <PageButton 
              onClick={() => handlePageChange(currentPage + 1)} 
              disabled={currentPage === totalPages}
            >
              Next
            </PageButton>
          </Pagination>
        </PageContainer>
      </>
    );
  };
  
  export default PostDetail;