import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  width: 100%;
  max-width: 1160px;
  margin: 0 auto;
  padding: 20px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FooterContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FooterLinks = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

const FooterLink = styled.a`
  color: black;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  font-weight: ${props => (props.bold ? '700' : '400')}; /* Use props.bold to conditionally apply the style */
  margin: 0 10px;
  text-decoration: none;
`;

const FooterInfo = styled.div`
  color: black;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  text-align: center;
  margin-bottom: 10px;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterLinks>
          <FooterLink bold href="#">Mate's 소개</FooterLink> {/* Ensure bold is passed as a boolean */}
          <FooterLink href="#">이용약관</FooterLink>
          <FooterLink href="#">개인정보처리방침</FooterLink>
          <FooterLink href="#">청소년 보호정책</FooterLink>
          <FooterLink href="#">게시중단안내</FooterLink>
          <FooterLink href="#">테스트방</FooterLink>
          <FooterLink href="#">문의+신고</FooterLink>
          <FooterLink href="#">문제보고</FooterLink>
        </FooterLinks>
        <FooterInfo>문의메일 : hand090399@gmail.com</FooterInfo>
        <FooterInfo>©Mate's All rights reserved.</FooterInfo>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
