import axios from 'axios';

const API_URL = 'https://api.koreamate.net'; 

const api = axios.create({
    baseURL: API_URL,
  });

api.interceptors.request.use(async (config) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });
  
api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const refreshToken = localStorage.getItem('refreshToken');
        const { data } = await axios.post(`${API_URL}/users/api/token/refresh/`, {
            refresh: refreshToken,
        });
        localStorage.setItem('accessToken', data.access);
        api.defaults.headers.Authorization = `Bearer ${data.access}`;
        return api(originalRequest);
        }
        return Promise.reject(error);
    }
);
  



export const checkEmail = async (email) => {
  const response = await axios.post(`${API_URL}/users/check`, { email });
  return response.data;
};

export const sendVerificationEmail = async (email) => {
  const response = await axios.post(`${API_URL}/users/sendemail`, { email });
  return response.data;
};

export const verifyEmail = async (email, code) => {
  const response = await axios.post(`${API_URL}/users/verify-email`, { email, code });
  return response.data;
};

export const signUp = async (userData) => {
  const response = await axios.post(`${API_URL}/users/signup`, userData);
  return response.data;
};

export const checkNickname = async (nickname) => {
    const response = await axios.post(`${API_URL}/users/check`, { nickname });
    return response.data;
  };

export const login = async (loginData) => {
    const response = await axios.post(`${API_URL}/users/login`, loginData);
    return response.data;
};

export default api;