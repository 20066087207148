import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Link } from 'react-router-dom';

const SectionHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const TitleAndTabContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const Title = styled.h2`
  font-size: 18px;
  font-weight: 700;
  font-family: 'Noticia Text', serif;
  line-height: 1.2;
`;

const TabMenu = styled.div`
  display: flex;
  gap: 10px;
`;

const TabItem = styled.div`
  font-size: 14px;
  font-weight: ${props => props.active ? '700' : '400'};
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const HomeContainer = styled.div`
  width: 100%;
  max-width: 1160px;
  margin: 0 auto;
  background: white;
  padding: 20px;
`;

const MainLayout = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 300px;

  &:nth-child(1) {
    max-width: 706px;
  }

  &:nth-child(2) {
    max-width: 343px;
  }
`;

const Section = styled.section`
  margin-bottom: 20px;
`;

const ContentBox = styled.div`
  width: 100%;
  background: white;
  border-radius: 10px;
  border: 1px solid black;
  padding: 20px;
`;

const SubTitle = styled.div`
  font-size: 14px;
  font-family: 'Noticia Text', serif;
  margin-bottom: 5px;
`;

const PostItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  border-bottom: 1px solid #e0e0e0;
`;

const Image = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  margin-top: 20px;
`;

const EventImage = styled.img`
  width: 100%;
  height: 322px;
  object-fit: cover;
`;

const NoticeBox = styled.div`
  width: 100%;
  background: #f0f0f0;
  padding: 10px;
  margin: 20px 0;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const NoticeTitle = styled.div`
  width: 162px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;

const NoticeContent = styled.div`
  flex: 1;
  font-size: 16px;
`;

const FullWidthImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  margin: 20px 0;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PaginationButton = styled.button`
  padding: 10px;
  margin: 0 5px;
  border: 1px solid #ccc;
  background: #fff;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const FadeTransition = styled.div`
  transition: opacity 0.3s ease-in-out;
  opacity: ${props => props.isVisible ? 1 : 0.5};
`;

const PostLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: underline;
  }
`;

const Home = () => {
  const [hotPosts, setHotPosts] = useState([]);
  const [qnaPosts, setQnaPosts] = useState([]);
  const [topSearched, setTopSearched] = useState([]);
  const [residentAlienNews, setResidentAlienNews] = useState([]);
  const [currentTab, setCurrentTab] = useState('None');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchHotPosts = useCallback(async (period = 'None', page = 1) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(`https://api.koreamate.net/boards/posts`, {
        params: {
          category: 'HOT',
          period: period,
          page: page,
          page_size: 10
        }
      });
      if (response.data.results) {
        setHotPosts(response.data.results);
        setTotalPages(response.data.total_pages);
        setCurrentPage(response.data.current_page);
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      setError('Error fetching hot posts');
      console.error('Error fetching hot posts', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchHotPosts(currentTab, 1);
    fetchQnAPosts();
    fetchTopSearched();
    fetchResidentAlienNews();
  }, [fetchHotPosts, currentTab]);

  const handlePageChange = (newPage) => {
    fetchHotPosts(currentTab, newPage);
  };

  const handleTabChange = (tab) => {
    if (tab !== currentTab) {
      setCurrentTab(tab);
      setCurrentPage(1);
      fetchHotPosts(tab, 1);
    }
  };

  const fetchQnAPosts = async () => {
    try {
      const response = await axios.get('https://api.koreamate.net/boards/posts', {
        params: {
          category: 'QA',
          page: 1,
          page_size: 10
        }
      });
      if (response.data.results) {
        setQnaPosts(response.data.results);
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Error fetching Q&A posts', error);
    }
  };

  const fetchTopSearched = async () => {
    try {
      const response = await axios.get('https://api.koreamate.net/search/top-searched/');
      if (Array.isArray(response.data)) {
        setTopSearched(response.data);
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Error fetching top searched', error);
    }
  };

  const fetchResidentAlienNews = async () => {
    try {
      const response = await axios.get('https://api.koreamate.net/news/resident-alien-news/');
      if (Array.isArray(response.data)) {
        setResidentAlienNews(response.data);
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Error fetching resident alien news', error);
    }
  };

  return (
    <HomeContainer>
      <MainLayout>
        <Column as="leftColumn">
          <Section>
            <SectionHeader>
              <TitleAndTabContainer>
                <Title>Hot ground</Title>
                <TabMenu>
                  <TabItem 
                    active={currentTab === 'daily'} 
                    onClick={() => handleTabChange('daily')}
                  >
                    Daily
                  </TabItem>
                  <TabItem 
                    active={currentTab === 'weekly'} 
                    onClick={() => handleTabChange('weekly')}
                  >
                    Weekly
                  </TabItem>
                  <TabItem 
                    active={currentTab === 'monthly'} 
                    onClick={() => handleTabChange('monthly')}
                  >
                    Monthly
                  </TabItem>
                </TabMenu>
              </TitleAndTabContainer>
            </SectionHeader>
            <ContentBox>
              <FadeTransition isVisible={!isLoading}>
                {error ? (
                  <div>{error}</div>
                ) : hotPosts && hotPosts.length > 0 ? (
                  hotPosts.map((post) => (
                    <PostItem key={post.id}>
                      <PostLink to={`/post/${post.id}`}>{post.title}</PostLink>
                      <div>
                        <span>{new Date(post.date_created).toLocaleDateString()}</span>
                        <span style={{ marginLeft: '10px' }}>{new Date(post.date_created).toLocaleTimeString()}</span>
                      </div>
                    </PostItem>
                  ))
                ) : (
                  <div>No posts found</div>
                )}
              </FadeTransition>
            </ContentBox>
            <PaginationContainer>
              <PaginationButton
                disabled={currentPage === 1 || isLoading}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </PaginationButton>
              <PaginationButton
                disabled={currentPage === totalPages || isLoading}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
              </PaginationButton>
            </PaginationContainer>
            <Image src="https://picsum.photos/706/200" alt="Featured content" />
          </Section>
        </Column>
        <Column as="rightColumn">
          <Section>
            <SectionHeader>
              <Title>Top Searched</Title>
            </SectionHeader>
            <ContentBox>
              {topSearched && topSearched.length > 0 ? (
                topSearched.map((item, index) => (
                  <SubTitle key={index}>{`${index + 1}. ${item.query}`}</SubTitle>
                ))
              ) : (
                <div>No top searched items found</div>
              )}
            </ContentBox>
          </Section>
          <Section>
            <SectionHeader>
              <Title>Resident Alien News</Title>
            </SectionHeader>
            <ContentBox>
              {residentAlienNews && residentAlienNews.length > 0 ? (
                residentAlienNews.map((news, index) => (
                  <PostItem key={index}>
                    <div>{news.title}</div>
                    <div>네이버</div>
                  </PostItem>
                ))
              ) : (
                <div>No news found</div>
              )}
            </ContentBox>
          </Section>
        </Column>
      </MainLayout>

      <NoticeBox>
        <NoticeTitle>공지</NoticeTitle>
        <NoticeContent>
          Mate's 서비스는 대한민국 체류외국인 이용자를 위한 공간으로 모든 이용자들은 아래 운영원칙을 준수해야 합니다.
        </NoticeContent>
      </NoticeBox>

      <MainLayout>
        <Column as="leftColumn">
          <Section>
            <SectionHeader>
              <Title>Q & A</Title>
            </SectionHeader>
            <ContentBox>
              {qnaPosts && qnaPosts.length > 0 ? (
                qnaPosts.map((post) => (
                  <PostItem key={post.id}>
                    <PostLink to={`/post/${post.id}`}>{post.title}</PostLink>
                    <div>
                      <span>{new Date(post.date_created).toLocaleDateString()}</span>
                      <span style={{ marginLeft: '10px' }}>{new Date(post.date_created).toLocaleTimeString()}</span>
                    </div>
                  </PostItem>
                ))
              ) : (
                <div>No Q&A posts found</div>
              )}
            </ContentBox>
          </Section>
        </Column>
        <Column as="rightColumn">
          <Section>
          <SectionHeader>
              <Title>Event</Title>
            </SectionHeader>
            <EventImage src="https://picsum.photos/343/322" alt="Current Event" />
          </Section>
        </Column>
      </MainLayout>
      
      <FullWidthImage src="https://picsum.photos/1160/200" alt="Featured content" />
    </HomeContainer>
  );
};

export default Home;