// src/components/Banner.js
import React from 'react';
import styled from 'styled-components';

const BannerContainer = styled.div`
  width: 100%;
  height: 200px;
  background-image: url(${props => props.backgroundImage});
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  border: 2px solid #000; // 윤곽선 추가
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); // 선택적: 그림자 효과 추가
`;

const BannerContent = styled.div`
  color: white;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5); // 선택적: 텍스트 배경 추가
  border-radius: 10px; // 선택적: 텍스트 배경의 모서리를 둥글게
`;

const Banner = ({ backgroundImage, children }) => {
  return (
    <BannerContainer backgroundImage={backgroundImage}>
      <BannerContent>{children}</BannerContent>
    </BannerContainer>
  );
};

export default Banner;