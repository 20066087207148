import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';

const PageContainer = styled.div`
  width: 100%;
  max-width: 1160px;
  margin: 0 auto;
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
`;

const ContentBox = styled.div`
  background: white;
  border-radius: 10px;
  border: 1px solid black;
  padding: 20px;
  margin-bottom: 20px;
`;

const PostItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;
`;

const Search = () => {
  const [postResults, setPostResults] = useState([]);
  const [newsResults, setNewsResults] = useState([]);
  const location = useLocation();
  const query = new URLSearchParams(location.search).get('query');

  useEffect(() => {
    const fetchSearchResults = async () => {
      try {
        const response = await axios.get('https://api.koreamate.net/boards/search', {
          params: { query },
        });
        setPostResults(response.data.posts || []);
        setNewsResults(response.data.news || []);
      } catch (error) {
        console.error('Error fetching search results', error);
        setPostResults([]);
        setNewsResults([]);
      }
    };

    if (query) {
      fetchSearchResults();
    }
  }, [query]);



  return (
    <PageContainer>
      <Title>Search Results for "{query}"</Title>
      <ContentBox>
        <h2>Posts</h2>
        {postResults.length > 0 ? (
          postResults.map((result) => (
            <PostItem key={result.id}>
              <div>{result.title}</div>
              <div>
                <span>{new Date(result.date_created).toLocaleDateString()}</span>
                <span style={{ marginLeft: '10px' }}>{new Date(result.date_created).toLocaleTimeString()}</span>
              </div>
            </PostItem>
          ))
        ) : (
          <p>No posts found.</p>
        )}
      </ContentBox>
      <ContentBox>
        <h2>News</h2>
        {newsResults.length > 0 ? (
          newsResults.map((result) => (
            <PostItem key={result.id}>
              <div>{result.title}</div>
              <div>
                <span>{new Date(result.created_at).toLocaleDateString()}</span>
                <span style={{ marginLeft: '10px' }}>{new Date(result.created_at).toLocaleTimeString()}</span>
              </div>
            </PostItem>
          ))
        ) : (
          <p>No news found.</p>
        )}
      </ContentBox>
    </PageContainer>
  );
};

export default Search;